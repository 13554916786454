import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import socials from './data';

const textColors = {
  black: tw`text-black-100`,
  white: tw`text-white`,
};
const Container = styled.div`
    ${tw`flex flex-col items-center p-4 my-4`}
`;
const Title = styled.div`
    ${tw`font-kgAlwaysAGoodTime text-base text-lg mb-4`}
    ${({ color }) => textColors[color]}
`;
const SocialItems = styled.div`
    ${tw`flex`}
`;

const SocialItem = styled.a`
    ${tw`mx-4`}
`;

const Socials = ({ text = 'retrouvez-moi sur les réseaux', color = 'black-100' }) => (
  <Container>
    <Title color={color}>{text}</Title>
    <SocialItems>
      {Object.keys(socials).map((key) => {
        const { icon, profile } = socials[key];
        const Icon = icon;
        return (
          <SocialItem href={profile} target="_blank" rel="noreferrer noopener" key={key}>
            <Icon color={color} hover />
          </SocialItem>
        );
      })}
    </SocialItems>
  </Container>
);

export default Socials;
