/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import BaseSVG from '../Svg/Svg';

const SVG = styled(BaseSVG)`
    ${tw`fill-current transition duration-200`}
    ${(props) => props.color === 'black-100' && tw`text-black-100`}
    ${(props) => props.color === 'white' && tw`text-white`}
    ${(props) => props.hover && tw`hover:text-gold`}
`;

const Instagram = ({
  width, height, color, hover,
}) => (
  <SVG
    width={width}
    height={height}
    hover={hover}
    color={color}
    viewBox="0 0 2572.6 2520.1"
  >
    <path
      d="M875.6,1260.1c0-226,183.2-409.3,409.2-409.3s409.3,183.3,409.3,409.3s-183.3,409.3-409.3,409.3
	S875.6,1486.1,875.6,1260.1 M654.3,1260.1c0,348.2,282.3,630.5,630.5,630.5s630.5-282.3,630.5-630.5s-282.3-630.5-630.5-630.5
	S654.3,911.8,654.3,1260.1 M1793,604.5c0,81.4,65.9,147.4,147.3,147.4c81.4,0,147.4-65.9,147.4-147.3c0-81.4-65.9-147.4-147.3-147.4
	h-0.1C1859,457.3,1793.1,523.2,1793,604.5 M788.8,2259.6c-119.7-5.5-184.8-25.4-228-42.2c-57.3-22.3-98.2-48.9-141.2-91.8
	s-69.6-83.8-91.8-141.1c-16.9-43.2-36.8-108.3-42.2-228c-6-129.4-7.1-168.3-7.1-496.2s1.3-366.7,7.1-496.2
	c5.5-119.7,25.5-184.7,42.2-228c22.3-57.3,48.9-98.2,91.8-141.2s83.8-69.6,141.2-91.8c43.2-16.9,108.3-36.8,228-42.2
	c129.4-6,168.3-7.1,496.1-7.1s366.7,1.3,496.2,7.1c119.7,5.5,184.7,25.5,228,42.2c57.3,22.2,98.2,48.9,141.2,91.8
	s69.5,83.9,91.8,141.2c16.9,43.2,36.8,108.3,42.2,228c6,129.5,7.1,168.3,7.1,496.2s-1.2,366.7-7.1,496.2
	c-5.5,119.7-25.5,184.8-42.2,228c-22.3,57.3-48.9,98.2-91.8,141.1s-83.9,69.5-141.2,91.8c-43.2,16.9-108.3,36.8-228,42.2
	c-129.4,6-168.3,7.1-496.2,7.1S918.2,2265.5,788.8,2259.6 M778.6,39.6c-130.7,6-220,26.7-298.1,57C399.8,128,331.4,170,263,238.2
	S152.7,375,121.4,455.8c-30.4,78.1-51.1,167.3-57,298.1c-6.1,130.9-7.4,172.8-7.4,506.3s1.4,375.3,7.4,506.3
	c6,130.7,26.7,220,57,298.1c31.3,80.7,73.3,149.3,141.6,217.5c68.3,68.2,136.7,110.2,217.5,141.6c78.2,30.4,167.3,51.1,298.1,57
	c131,6,172.8,7.4,506.3,7.4s375.3-1.4,506.3-7.4c130.7-6,220-26.7,298.1-57c80.7-31.4,149.2-73.4,217.5-141.6
	c68.3-68.2,110.2-136.8,141.6-217.5c30.4-78.1,51.2-167.3,57-298.1c6-131,7.3-172.8,7.3-506.3s-1.4-375.3-7.3-506.3
	c-6-130.7-26.7-220-57-298.1c-31.4-80.7-73.4-149.2-141.6-217.5c-68.2-68.3-136.8-110.3-217.4-141.6c-78.2-30.4-167.4-51.2-298.1-57
	c-130.9-6-172.8-7.4-506.3-7.4S909.6,33.5,778.6,39.6"
    />
  </SVG>
);

Instagram.defaultProps = {
  width: '2.25rem',
  height: '2.25rem',
  hover: false,
  color: 'black-100',
};

Instagram.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  hover: PropTypes.bool,
  color: PropTypes.string,
};

export default Instagram;
