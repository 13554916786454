/* eslint-disable no-prototype-builtins */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* Font configuration */
import 'fontsource-lato/300.css';
import 'fontsource-lato/400.css';
import 'fontsource-lato/700.css';
import 'fontsource-lato/900.css';

import 'fontsource-montserrat/300.css';
import 'fontsource-montserrat/500.css';
import 'fontsource-montserrat/600.css';
import 'fontsource-montserrat/700.css';

import 'fontsource-open-sans/400.css';
import 'fontsource-open-sans/600.css';

import './src/styles/index.css';

import React from 'react';
import { Helmet } from 'react-helmet';
import Prefooter from './src/widgets/Prefooter/Prefooter';
import Footer from './src/widgets/Footer/Footer';
import GlobalStateProvider from './src/state/globalStateProvider';
import MobileMenu from './src/widgets/MobileMenu/MobileMenu';

const Wrapper = ({ element }) => (
  <>
    <Helmet>
      <html lang="fr" />
    </Helmet>
    <GlobalStateProvider>
      <MobileMenu />
      {element}
      <Prefooter />
      <Footer />
    </GlobalStateProvider>
  </>
);

export const wrapRootElement = Wrapper;
