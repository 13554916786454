import React, { useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CSSTransition } from 'react-transition-group';
import { useGlobalState } from '../../state/globalStateContext';
import BaseMobileMenu from '../../components/MobileMenu/MobileMenu';
import { CloseMobileMenuAction, MobileMenuClickAction } from '../../state/actions';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import links from '../../routing/links';
import './MobileMenu.css';

const MobileMenu = () => {
  const [{ mobileMenuOpen }, dispatch] = useGlobalState();
  const shouldCloseMenu = useMediaQuery({ query: '(min-width: 768px)' });

  const onClickHandler = useCallback(() => {
    dispatch(MobileMenuClickAction());
  }, [dispatch]);

  const closeMenuHandler = useCallback(() => {
    if (mobileMenuOpen) { dispatch(CloseMobileMenuAction()); }
  }, [dispatch, mobileMenuOpen]);

  const ref = useOnClickOutside(closeMenuHandler);

  useEffect(() => {
    if (shouldCloseMenu) {
      closeMenuHandler();
    }
  }, [shouldCloseMenu, closeMenuHandler]);

  return (
    <>
      <CSSTransition
        in={mobileMenuOpen}
        timeout={300}
        classNames="mobileMenu"
        unmountOnExit
        appear
      >
        <BaseMobileMenu links={links} onClick={onClickHandler} ref={ref} />
      </CSSTransition>
    </>
  );
};

export default MobileMenu;
