import styled from 'styled-components';
import tw from 'twin.macro';

const StyledButton = styled.button`
  ${tw`flex items-center justify-center`}
  ${tw`py-7 px-10 h-12`}
  ${tw`bg-black-100 text-white uppercase border-solid border-2 border-black-100 focus:outline-none cursor-pointer`}
  ${tw`hover:bg-white hover:text-black-100`}
  ${tw`font-lato font-bold text-sm sm:text-base tracking-wider`}
  ${tw`transition duration-200`}
  ${tw`disabled:bg-gray-700 disabled:border-gray-700 disabled:text-white disabled:cursor-default`}
  min-width: 3rem;
`;

export default StyledButton;
