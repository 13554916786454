import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const footerData = {
  copyright: '© 2015-2022 ANNE-SOPHIE LAGOËT - Architecte d’Intérieur, Montmorency.',
};

const Container = styled.footer`
      ${tw`flex items-center h-20 bg-black-100 w-full px-8 sm:px-24  mt-32`}
  `;

const Copyright = styled.span`
      ${tw`font-lato text-xs text-white`}
  `;

const Footer = () => (
  <Container>
    <Copyright>{footerData.copyright}</Copyright>
  </Container>
);

export default Footer;
