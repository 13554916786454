import { useStaticQuery, graphql } from 'gatsby';

export const useInstagramWidgetData = () => {
  const { contentfulWidgetInstagram: { images } } = useStaticQuery(
    graphql`
    query MyQuery {
        contentfulWidgetInstagram(title: {eq: "main"}) {
          id
          images {
            id
            fixed(height: 500, width: 500, cropFocus: CENTER) {
              src
            }
          }
        }
      }
    `,
  );
  return images;
};

export default useInstagramWidgetData;
