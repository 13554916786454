import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'gatsby';
import config from '../../../tailwind.config';
import Facebook from '../Icons/Facebook';
import Instagram from '../Icons/Instagram';
import Pinterest from '../Icons/Pinterest';

const StyledMobileMenu = styled.div`
    ${tw`flex flex-col absolute h-screen bg-black-100 z-10 fixed w-3/4 pt-12`}
`;

const List = styled.ul``;

const Item = styled.li`
    ${tw`flex items-center px-8 border-t h-12 border-color-10`}
`;

const NavLink = styled(Link)`
    ${tw`font-lato text-sm text-white hover:text-gold transition duration-200 uppercase tracking-wider`}
`;

const SocialItem = styled.a`
    ${tw`mr-4`}
`;

const socials = [
  { icon: Facebook, profile: 'https://www.facebook.com/annesophielagoet' },
  { icon: Instagram, profile: 'https://www.instagram.com/annesophielagoet' },
  { icon: Pinterest, profile: 'http://pinterest.com/annesophielagoet' },
];

const MobileMenu = React.forwardRef(({ links, onClick }, ref) => (
  <StyledMobileMenu ref={ref}>
    <List>
      {links.map(({ to, label }) => (
        <Item>
          <NavLink
            onClick={onClick}
            to={to}
            activeStyle={{
              color: config.theme.extend.colors.gold,
            }}
          >
            {label}
          </NavLink>
        </Item>
      ))}
    </List>
    <Item as="div">
      {socials.map(({ icon, profile }) => {
        const Icon = icon;
        return (
          <SocialItem href={profile} target="_blank" rel="noreferrer noopener">
            <Icon width="1.5rem" height="1.5rem" color="white" hover />
          </SocialItem>
        );
      })}
    </Item>
  </StyledMobileMenu>
));

MobileMenu.defaultProps = {
  links: [],
  onClick: () => {},
};

MobileMenu.propTypes = {
  links: PropTypes.arrayOf(PropTypes.any),
  onClick: PropTypes.func,
};

export default MobileMenu;
