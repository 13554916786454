/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import BaseSVG from '../Svg/Svg';

const SVG = styled(BaseSVG)`
    ${tw`fill-current transition duration-200`}
    ${(props) => props.color === 'black-100' && tw`text-black-100`}
    ${(props) => props.color === 'white' && tw`text-white`}
    ${(props) => props.hover && tw`hover:text-gold`}
`;

const Pinterest = ({
  width, height, color, hover,
}) => (
  <SVG
    width={width}
    height={height}
    hover={hover}
    color={color}
    viewBox="0 0 2572.6 2520.1"
  >
    <path
      d="M69.4,1260.2c6.3-345.3,128.3-633.7,366-864.9S956.8,46.5,1286.3,43.3c354.8,6.3,645.7,127.5,872.2,363.6
   c226.6,236.1,341.4,520.6,344.6,853.3c-6.3,342.2-128.3,629.8-366,862.7c-237.8,232.7-521.3,350.7-850.8,353.9
   c-114.1,0-228-17.5-342.2-52.3c22.1-34.8,44.3-74.5,66.4-118.8c25.3-53.8,56.9-155.3,95.2-304.2c9.5-41.1,23.9-96.6,42.8-166.5
   c22.1,38,60.1,72.8,114.1,104.6c142.6,66.4,293,60.1,451.5-19c164.8-95.2,278.9-242.4,342.2-442c56.9-209.1,49.9-403.3-21.4-582.1
   c-71.3-179.1-198.8-308.1-382.6-387.4c-231.2-66.4-456.3-59.4-674.9,21.4S606.8,750.1,521.1,946.5c-22.1,72.8-35.5,145-40.4,216.4
   s-2.4,140.9,7.1,209.1c9.5,68.1,33.3,129.2,71.3,183c38,53.8,88.8,95.2,152.1,123.6c15.8,6.3,28.5,6.3,38,0
   c12.7-6.3,25.3-31.6,38-75.9c12.7-44.3,17.5-74.5,14.4-90.3c-3.2-6.3-9.5-17.5-19-33.3c-66.4-107.8-88.8-221-66.4-339.7
   c22.1-118.8,71.3-219.5,147.2-301.8c117.3-104.6,254.3-161.6,411.1-171.1c156.7-9.5,290.8,33.3,401.6,128.3
   c60.1,69.6,99.1,153.6,116.3,251.9s17.5,192.5,0,282.8c-17.5,90.3-48.4,175-92.7,254.3c-79.3,123.6-172.8,186.9-280.4,190.1
   c-63.3-3.2-114.9-27.7-154.5-73.7c-39.7-46-51.6-99.1-35.5-159.2c6.3-34.8,25.3-102.9,56.9-204.4s49.2-177.4,52.3-228
   c-9.5-120.5-65-182.3-166.5-185.4c-79.3,9.5-137.7,45.3-176,106.8c-38.2,61.6-58.7,132.4-61.8,211.5c9.5,92,23.9,155.3,42.8,190.1
   c-34.8,142.6-61.8,256.8-80.8,342.2c-6.3,22.1-23.9,90.3-52.3,204.4c-28.5,114.1-44.3,201.3-47.5,261.4v133.1
   c-224.9-104.6-402.5-255.8-532.2-453.9S69.4,1500.9,69.4,1260.2z"
    />
  </SVG>
);

Pinterest.defaultProps = {
  width: '2.25rem',
  height: '2.25rem',
  hover: false,
  color: 'black-100',
};

Pinterest.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  hover: PropTypes.bool,
  color: PropTypes.string,
};

export default Pinterest;
