/* eslint-disable no-prototype-builtins */
const {
  MOBILE_MENU_CLICKED,
  CLOSE_MOBILE_MENU,
} = require('./actionTypes');

const actions = {
  [MOBILE_MENU_CLICKED]: (state) => ({ ...state, mobileMenuOpen: !state.mobileMenuOpen }),
  [CLOSE_MOBILE_MENU]: (state) => ({ ...state, mobileMenuOpen: false }),
};

export const initialState = {
  mobileMenuOpen: false,
};

export const reducer = (state, action) => {
  if (!actions.hasOwnProperty(action.type)) {
    throw new Error('Unexpected action');
  }
  return actions[action.type](state, action);
};
