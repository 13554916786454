module.exports = {
  purge: ['./src/**/*.js', './src/**/*.jsx', './src/**/*.ts', './src/**/*.tsx'],
  theme: {
    container: {
      center: true,
    },
    extend: {
      spacing: {
        100: '25rem',
        80: '20rem',
        72: '18rem',
        96: '23rem',
      },
      padding: {
        7: '1.75rem',
      },
      margin: {
        7: '1.75rem',
      },
      fontSize: {
        '2xxl': '1.75rem',
        '5xxl': '3.25rem',
      },
      lineHeight: {
        tiny: '1.125',
      },
      colors: {
        'black-100': '#13191f',
        'black-200': '#32373C',
        'black-300': '#19131f',
        'black-400': '#191515',
        'gray-100': '#f1f1f1',
        'gray-200': '#F8F8F8',
        pink: '#FD3443',
        gold: '#af8a53',
        prune: '#69253F',
        terracotta: '#6F2D08',
        'prune-100': '#802F44',
        'color-3': '#5b8e8e',
        'color-4': '#e9e9e9',
        'color-5': '#1a4b38',
        'color-6': '#919396',
        'color-7': '#767474',
        'color-9': '#6F2D08',
        'color-10': '#ffffff45',
        'color-11': '#0C5353',
        '#2A6969': '#2A6969',
      },
      fontFamily: {
        lato: ['Lato'],
        montserrat: ['Montserrat'],
        openSans: ['Open Sans'],
        kgAlwaysAGoodTime: ['KG Always A Good Time'],
      },
      opacity: {
        90: '0.9',
        60: '0.6',
        50: '0.5',
        20: '0.2',
        15: '0.15',
        10: '0.1',
      },
    },
  },
  variants: {},
  plugins: [],
};
