import {
  CLOSE_MOBILE_MENU,
  MOBILE_MENU_CLICKED,
} from './actionTypes';

export const MobileMenuClickAction = () => ({
  type: MOBILE_MENU_CLICKED,
});

export const CloseMobileMenuAction = () => ({
  type: CLOSE_MOBILE_MENU,
});
