// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-architecture-interieur-architecture-interieur-jsx": () => import("./../../../src/containers/ArchitectureInterieur/ArchitectureInterieur.jsx" /* webpackChunkName: "component---src-containers-architecture-interieur-architecture-interieur-jsx" */),
  "component---src-containers-contact-contact-jsx": () => import("./../../../src/containers/Contact/Contact.jsx" /* webpackChunkName: "component---src-containers-contact-contact-jsx" */),
  "component---src-containers-decoration-interieur-decoration-interieur-jsx": () => import("./../../../src/containers/DecorationInterieur/DecorationInterieur.jsx" /* webpackChunkName: "component---src-containers-decoration-interieur-decoration-interieur-jsx" */),
  "component---src-containers-home-home-jsx": () => import("./../../../src/containers/Home/Home.jsx" /* webpackChunkName: "component---src-containers-home-home-jsx" */),
  "component---src-containers-realisations-realisations-jsx": () => import("./../../../src/containers/Realisations/Realisations.jsx" /* webpackChunkName: "component---src-containers-realisations-realisations-jsx" */),
  "component---src-templates-realisation-realisation-jsx": () => import("./../../../src/templates/Realisation/Realisation.jsx" /* webpackChunkName: "component---src-templates-realisation-realisation-jsx" */)
}

