/* eslint-disable import/no-named-as-default */
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Image from '../../components/Image/Image';
import InstagramIcon from '../../components/Icons/Instagram';
import useInstagramWidgetData from './useInstagramWidgetData';
import socials from '../Socials/data';

const Container = styled.div`
    ${tw`flex flex-col items-center`}
`;

const IconContainer = styled.a`
    ${tw`mb-8`}
`;

const Gallery = styled.div`
    ${tw`grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2 sm:gap-4 lg:gap-8 px-4`}
`;

const StyledImage = styled(Image)`
    ${tw`w-full sm:w-40 md:w-48 lg:w-56 max-w-full`}
    @media (min-width: 1024px) {
      :last-child {
        display: none;
    }
  }
`;

const Instagram = () => {
  const images = useInstagramWidgetData();

  return (
    <Container>
      <IconContainer href={socials.instagram.profile} target="_blank" rel="noreferrer noopener">
        <InstagramIcon hover />
      </IconContainer>
      <Gallery>
        {images.map(({ id, fixed: { src } }) => <StyledImage src={src} key={id} />)}
      </Gallery>
    </Container>
  );
};

export default Instagram;
