import axios from 'axios';

const SubscribeToNewsletter = async (subscriberEmail) => {
  const payload = {
    subscriber: subscriberEmail,
  };

  return axios.post('/api/newsletter/subscribe', payload);
};

export default SubscribeToNewsletter;
