/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import BaseSVG from '../Svg/Svg';

const SVG = styled(BaseSVG)`
    ${tw`fill-current transition duration-200`}
    ${(props) => props.color === 'black-100' && tw`text-black-100`}
    ${(props) => props.color === 'white' && tw`text-white`}
    ${(props) => props.hover && tw`hover:text-gold`}
`;

const Facebook = ({
  width, height, color, hover,
}) => (
  <SVG
    width={width}
    height={height}
    hover={hover}
    color={color}
    viewBox="0 0 1298 2500"
  >
    <path
      d="M839.8,2482.2V1358.1h377.3l56.5-438.1H839.8V640.3c0-126.8,35.2-213.3,217.1-213.3l232-0.1V35
	  c-40.1-5.3-177.8-17.2-338.1-17.2c-334.5,0-563.5,204.2-563.5,579.1V920H9.1v438.1h378.3v1124.2L839.8,2482.2L839.8,2482.2z"
    />
  </SVG>
);

Facebook.defaultProps = {
  width: '2.25rem',
  height: '2.25rem',
  hover: false,
  color: 'black-100',
};

Facebook.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  hover: PropTypes.bool,
  color: PropTypes.string,
};

export default Facebook;
