import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import tw from 'twin.macro';
import ActionButton from '../../components/Button/ActionButton';

const Container = styled.div`
${tw`p-4`}
`;

const Title = styled.div`
    ${tw`font-kgAlwaysAGoodTime text-3xl mb-1 text-pink text-center`}
`;

const Text = styled.div`
    ${tw`font-lato font-black tracking-widest text-xs sm:text-sm text-black-100 mb-2 text-center`}
`;

const Form = styled.form``;

const FormResult = styled.div`
  ${tw`flex justify-center`}
`;

const FormInput = styled.div`
  ${tw`flex items-center justify-center flex-wrap`}
`;

const FormMessage = styled.p`
${tw`font-lato tracking-wider text-sm md:text-base text-center`}
`;

const ErrorMessage = styled(FormMessage)`
  ${tw`text-red-600`}
`;

const SuccessMessage = styled(FormMessage)`
  ${tw`text-green-600`}
`;

const InputText = styled.input`
    ${tw`font-lato w-56 sm:w-72 min-w-full sm:min-w-0 text-sm tracking-wider px-4 box-border text-color-6 border border-solid border-color-7 m-4`}
    height: 3.75rem;
`;

const schema = yup.object().shape({
  email: yup.string().email('invalid email').required('email is required'),
});

const Newsletter = ({
  title, description, buttonTitle, onSubmit, isSubmitting, isError, isSuccess,
}) => {
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Container>
      <Title>{title}</Title>
      <Text>{description}</Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormResult>
          {isError && (
          <ErrorMessage>
            Une erreur s'est produite, merci de reesayer plus tard.
          </ErrorMessage>
          )}
          {isSuccess && (
          <SuccessMessage>
            Votre demande a bien été prise en compte 🙂
          </SuccessMessage>
          )}
        </FormResult>
        <FormInput>
          <InputText ref={register} type="text" placeholder="Votre Email :" name="email" />
          <ActionButton type="submit" loading={isSubmitting} disabled={isSubmitting}>{buttonTitle}</ActionButton>
        </FormInput>
      </Form>
    </Container>
  );
};

Newsletter.defaultProps = {
  buttonTitle: 'Recevoir les news',
  description: 'Inscrivez-vous pour recevoir les news !',
  isError: false,
  isSubmitting: false,
  isSuccess: false,
  onSubmit: () => {},
  title: 'Newsletter',
};

Newsletter.propTypes = {
  buttonTitle: PropTypes.string,
  description: PropTypes.string,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};
export default Newsletter;
