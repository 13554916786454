import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import tw from 'twin.macro';
import SubscribeToNewsletter from '../../services/newsletter.service';
import Instagram from '../Instagram/Instagram';
import Newsletter from '../Newsletter/Newsletter';
import Socials from '../Socials/Socials';

const Container = styled.div`
  ${tw`pt-16 sm:pt-24 bg-white`}
`;

const Grid = styled.div`
  ${tw`flex mt-20 flex-wrap items-end justify-center gap-x-24 gap-y-4`}
`;

const Prefooter = () => {
  const [subscribe, { isLoading, isError, isSuccess }] = useMutation(SubscribeToNewsletter);

  const onSubmitNewsletter = useCallback(({ email }) => {
    subscribe(email);
  }, [subscribe]);
  return (
    <Container>
      <Instagram />
      <Grid>
        <Socials />
        <Newsletter
          onSubmit={onSubmitNewsletter}
          isSubmitting={isLoading}
          isError={isError}
          isSuccess={isSuccess}
        />
      </Grid>
    </Container>
  );
};

export default Prefooter;
