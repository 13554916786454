/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useReducer } from 'react';
import { initialState, reducer } from './reducer';
import { GlobalStateContext } from './globalStateContext';

const GlobalStateProvider = ({ children }) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={contextValue}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
